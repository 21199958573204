<template>
  <section class="content">
    <table class="table table-hover" ref="tblrekening">
      <thead>
        <tr>
          <th>BANK</th>
          <th>REKENING</th>
          <th>ATAS NAMA</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p>
              <div class="col-md-6 form-group">
                <label class="control-label">Bank</label>
                <!-- <select
                    id="bankid"
                    v-model="form.bank_id"
                    class="form-control"
                  >
                    <option
                      v-for="bank in groups"
                      v-bind:value="bank.id"
                      v-bind:key="bank.id"
                    >
                      {{ bank.label }}
                    </option>
                </select> -->
                <v-select :options="groups" v-model="form.bank_id" :reduce="opt => opt.id" />
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">COA</label>
                <v-select :options="coa" v-model="form.coa_id" :reduce="opt => opt.id" />
                <!-- <select
                    id="bankid"
                    v-model="form.coa_id" class="form-control">
                    <option
                      v-for="row in coa"
                      v-bind:value="row.id"
                      v-bind:key="row.id">
                      {{ row.label }}
                    </option>
                </select> -->
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">Rekening</label>
                <input
                  id="rekening" class="form-control" v-model="form.rekening" type="number" required="required" name="rekening" autocomplete="off" />
              </div>
              <div class="col-md-6 form-group">
                <label class="control-label">Atas nama</label>
                <input
                  id="atas_nama" class="form-control" v-model="form.atas_nama" type="text" name="atas_nama" autocomplete="off" />
              </div>
              <div class="col-md-6 form-group">
                  <label class="control-label"> </label>
                  <input type="checkbox" name="active" checked="checked" v-model="form.active"/>
                  <label for="active">Active</label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "RekeningBoscod",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      groups: [],
      coa: [],
      formTitle: "Tambah Rekening BOSCOD",
      form: {
        bank_id: "",
        coa_id: "",
        rekening: "",
        atas_nama: "",
        active: "",
      },
    };
  },
  components: {
        vSelect,
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch("/shipper/bank")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });

    authFetch("/shipper/coa")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.coa = js.data;
      });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/master/rekening";
      if (this.method == "PUT") urlSubmit = "/master/rekening/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    var self = this;
    this.table = createTable(e.tblrekening, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/master/rekening",
      processing: true,
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      columns: [
        { data: "bank" },
        { data: "rekening" },
        { data: "atas_nama" },
        { data: "active", 
        render: function (data, type, row, meta) {
          if (data > 0) {
            return '<span class="badge badge-success">Active</span>'
          } else {
            return '<span class="badge badge-warning">Not Active</span>'
          }
        }, },
      ],
      filterBy: [1, 2],
      rowCallback: function (row, data) {
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Rekening BOSCOD";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          if (evt.data.active < 1 || !evt.data.active) {
            self.form.active = false;
          } else {
            self.form.active = true;
          }
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Rekening BOSCOD";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/master/rekening/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
      initComplete: function () {
        $('.loading-overlay').removeClass('show');
      },
    });
  },
};
</script>